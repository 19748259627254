import { computed, type ComputedRef } from 'vue'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'

dayjs.extend(relativeTime)
dayjs.extend(duration)
dayjs.extend(LocalizedFormat)

export const useDate = (date?: string): dayjs.Dayjs => dayjs(date)

export const useHumanDate = (future: dayjs.Dayjs): ComputedRef<string> => computed(() => {
  const today = dayjs()
  if (today > future) {
    return 'now'
  }
  if (today.date() === future.date() - 1) {
    return 'tomorrow'
  }
  if (future.date() - today.date() < 5) {
    return `${future.fromNow()}`
  }
  return `on ${future.format('MMMM DD')}`
})

export const useHumanDuration = (duration: string | number): ComputedRef<string> => computed(() => {
  if(typeof duration === 'number') {
    return dayjs.duration(duration, 'seconds').format('m:ss')
  }
  const time = dayjs.duration(duration)
  const hours = time.hours()
  const minutes = time.minutes()
  const seconds = time.seconds()
  if (hours > 0) {
    return `${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
  }
  return `${minutes}:${seconds.toString().padStart(2, '0')}`
})
