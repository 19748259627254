import assetsJSON from '@/data/assets.json'
import logger from '@/plugins/logger'
import useBlurhash from '@/composables/useBlurhash'

export type RawAsset = {
  path: string;
  width: number;
  height: number;
  size: number;
  ext: string;
  mime: string;
  blurhash: string;
  thumbnails: RawAsset[];
}

const globalAssets = Object.fromEntries(Object.entries(import.meta.glob('@/assets/**/*.*', { eager: true }) as unknown as Record<string, {
  default: string;
}>).map(([key, value]) => [key, value.default]),)

export interface AssetInterface {
  readonly name: string;
  readonly path: string;
  readonly width: number;
  readonly height: number;
  readonly size: number;
  readonly ext: string;
  readonly mime: string;

  getPath (): string;

  getWidth (): number;

  getHeight (): number;

  getSize (): number;

  getExt (): string;

  getMime (): string;
}

export abstract class AssetAbstract implements AssetInterface {
  readonly name: string
  readonly path: string
  readonly width: number
  readonly height: number
  readonly size: number
  readonly ext: string
  readonly mime: string

  protected constructor (rawAsset: RawAsset) {
    this.name = rawAsset.path
    this.path = rawAsset.path
    this.width = rawAsset.width
    this.height = rawAsset.height
    this.size = rawAsset.size
    this.ext = rawAsset.ext
    this.mime = rawAsset.mime
  }

  getPath (): string {
    const path = globalAssets[`/${this.path}`]
    if (!path) {
      console.error(`Global Asset ${this.path} not found among global assets`, globalAssets)
      throw new Error(`Global Asset ${this.path} not found`)
    }
    return path
  }

  getWidth = (): number => this.width
  getHeight = (): number => this.height
  getSize = (): number => this.size
  getExt = (): string => this.ext
  getMime = (): string => this.mime
}

export class Asset extends AssetAbstract {
  private readonly blurhash: string
  private readonly thumbnails: ResizedAsset[]

  constructor (rawAsset: AssetInterface & RawAsset) {
    super(rawAsset)
    this.blurhash = rawAsset.blurhash
    this.thumbnails = rawAsset.thumbnails.map(thumbnail => new ResizedAsset(thumbnail))
  }

  getThumbnails = (): ResizedAsset[] => this.thumbnails

  // Return the biggest thumbnail that is smaller than maxWidth
  getResized = (maxWidth: number): ResizedAsset => {
    return this.thumbnails.reduce((prev, curr) => {
      if (curr.getWidth() < maxWidth && curr.getWidth() > prev.getWidth()) {
        return curr
      }
      return prev
    }, this.thumbnails[0])
  }

  getBlurhash = (): string => this.blurhash
  getBlurhashDataUrl = async (): Promise<string> => {
    const time = new Date().getTime()
    const thumbnailWidth = this.width < 300 ? this.width : 300
    const thumbnailHeight = Math.round(thumbnailWidth * (this.height / this.width))
    const dataUrl = await useBlurhash(this.blurhash, thumbnailWidth, thumbnailHeight)
    logger.debug(`Blurhash for ${this.name} rendered at ${thumbnailWidth}x${thumbnailHeight} (${this.width}x${this.height}) in`, new Date().getTime() - time, 'ms')
    return dataUrl
  }
}

export class ResizedAsset extends AssetAbstract {
  constructor (rawAsset: RawAsset) {
    super(rawAsset)
  }
}

class Assets {
  private readonly assets: { [key: string]: Asset } = {}

  constructor () {
    logger.debug('Constructing assets')
    const assets = assetsJSON as unknown as {
      [key: string]: AssetInterface & RawAsset;
    }
    for (const [name, rawAsset] of Object.entries(assets)) {
      this.assets[name] = new Asset(rawAsset)
    }
  }

  get = (name: string): Asset => {
    if (!this.assets[name]) {
      console.error(`Asset ${name} not found`)
      throw new Error(`Asset ${name} not found`)
    }
    return this.assets[name]
  }

  search = (query: string): Asset | undefined => Object.values(this.assets).filter(asset => asset.name.includes(query.toLowerCase()))[0]
}

export default new Assets()
