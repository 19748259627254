import { ModelWithAsset } from '@/models/model'
import type { RawMember } from '@/data'

class Member extends ModelWithAsset {
  id: string
  name: string
  type: string
  endDate?: string
  member: {
    id: string;
    name: string;
    type: string[];
  }
  roleName: string
  startDate: string

  constructor (member: RawMember & Partial<Member>) {
    super(member)
    this.id = member.member.id
    this.name = member.member.name
    this.type = member.type
    this.endDate = member.endDate
    this.member = member.member
    this.roleName = member.roleName
    this.startDate = member.startDate
  }

  isActive (): boolean {
    return !this.endDate
  }
}

export default Member
