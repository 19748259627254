const useSlug = (text: string, compact = false): string => {
  if (!text || text === '') {
    throw new Error('No text provided to slugify')
  }
  let slug = text.toLowerCase()
  if (compact) {
    slug = slug.replace(/\(.+?\)/g, '').replace(/\s+/g, '').trim()
  }
  slug = slug.replace(/ /g, '_').replace(/[^a-z0-9_]/g, '')
  return slug
}

export default useSlug
