<script lang="ts" setup>
import Assets, { Asset, ResizedAsset } from '@/plugins/assets'
import { onMounted, ref } from 'vue'
import logger from '@/plugins/logger'
import { Image, type Thumbnail } from '@/models/release'

const props = defineProps<{
  remote?: Image;
  local?: string;
  alt: string;
  class?: string;
}>()

const src = ref<string>('')
const srcset = ref<string>('')
const sizes = ref<string>('')
const blurhashDataUrl = ref<string>('')
const isLocalAsset = ref<boolean>(true)
const imageLoaded = ref(false)

const localAsset = async (asset: Asset): Promise<void> => {
  logger.debug('Using local asset')
  isLocalAsset.value = true
  blurhashDataUrl.value = await asset.getBlurhashDataUrl()
  src.value = asset.getResized(300).getPath()
  const thumbnails = asset.getThumbnails()
  srcset.value = thumbnails.map((asset: ResizedAsset) => `${asset.getPath()} ${asset.getWidth()}w`).join(', ')
  sizes.value = thumbnails.map((asset: ResizedAsset) => `(max-width: ${asset.getWidth()}px) ${asset.getWidth()}px`).join(', ')
}

const remoteAsset = (image: Image): void => {
  logger.debug('Using remote asset')
  isLocalAsset.value = false
  src.value = image.contentUrl
  srcset.value = image.thumbnail.map<string>((thumb: Thumbnail) => `${thumb.contentUrl} ${thumb.width}w`).join(', ')
  sizes.value = image.thumbnail.map<string>((thumb: Thumbnail) => `(max-width: ${thumb.width}px) ${thumb.width}px`).join(', ')
}

onMounted(async () => {
  if (props.local) {
    const asset = Assets.get(props.local)
    await localAsset(asset)
  }

  if (props.remote) {
    try {
      if (!props.local) {
        throw new Error('Name is required for remote assets')
      }
      const asset = Assets.search(props.local)
      if (asset) {
        await localAsset(asset)
      } else {
        throw new Error('Local asset not found')
      }
    } catch (error) {
      logger.debug(`Could not find local asset for: ${props.local}. Using remote asset`, error)
      remoteAsset(props.remote)
    }
  }
})

</script>

<template>
  <img
    v-if="isLocalAsset"
    v-show="!imageLoaded"
    key="img"
    :alt="alt"
    :class="props.class ?? 'w-full h-full object-cover'"
    :src="blurhashDataUrl"
  />
  <img
    v-show="imageLoaded"
    key="img"
    :alt="alt"
    :class="props.class ?? 'w-full h-full object-cover'"
    :sizes="sizes"
    :src="src"
    :srcset="srcset"
    v-bind="$attrs"
    @load="imageLoaded = true"
  />
</template>

<style scoped>

</style>
