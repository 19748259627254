<script lang="ts" setup>
import ImageResponsive from '@/components/ImageResponsive.vue'
import type Release from '@/models/release'
import { computed } from 'vue'

const props = defineProps<{
  release: Release;
}>()

const localAssetName = props.release.album.releaseType + '_' + props.release.longSlug

const font = computed(() => {
  console.log(props.release.name)
  switch (props.release.name) {
    case 'Plastic Ocean':
      return 'font-plasticOcean lowercase'
    case 'After the Storm (That Never Came)':
      return 'font-afterTheStorm tracking-widest'
    case 'Backworld':
      return 'font-backworld font-bold'
    default:
      return 'font-default'
  }
})

</script>

<template>
  <div class="flex flex-wrap md:flex-nowrap justify-center gap-5">
    <div class="md:basis-1/3 basis-full">
      <ImageResponsive
        :alt="release.name"
        :local="localAssetName"
        :remote="release.image"
        class="aspect-square w-full"
      />
    </div>
    <div class="md:basis-2/3 basis-full">
      <h2 class="text-xl font-bold" :class="font">{{ release.name }}</h2>
      <ul>
        <li class="flex justify-between">
          <span>Release Date</span>
          <span>{{ release.releaseDate.format('LL') }}</span>
        </li>
        <li v-if="release.UPC" class="flex justify-between">
          <span>UPC</span>
          <span>{{ release.UPC }}</span>
        </li>
        <li v-if="release.label" class="flex justify-between">
          <span>Label</span>
          <span>{{ release.label }}</span>
        </li>
        <li v-if="release.catalog" class="flex justify-between">
          <span>Catalog</span>
          <span>{{ release.catalog }}</span>
        </li>
      </ul>
      <hr>
      <ul v-if="release.tracks">
        <li v-for="track in release.tracks.items" :key="track.name" class="flex justify-between">
          <span :class="font">{{ track.name }}</span>
          <span>{{ track.humanReadableDuration }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped>

</style>
