import { Model } from '@/models/model'
import type { RawVideo } from '@/data'
import { useDate, useHumanDate } from '@/composables/useDate'
import useSlug from '@/composables/useSlug'

class Video extends Model {
  readonly id: string
  readonly name: string
  private readonly rawHeading: string
  private readonly title: string
  private readonly youtubeId: string
  private readonly published: string
  private readonly visible: boolean
  private readonly premiere?: boolean

  constructor (video: RawVideo & Partial<Video>) {
    super(video)
    this.name = video.title
    this.id = useSlug(video.title, true)
    this.rawHeading = video.heading
    this.title = video.title
    this.youtubeId = video.youtubeId
    this.published = video.published
    this.visible = video.visible
    this.premiere = video.premiere
  }

  get embedUrl (): string {
    return `https://www.youtube.com/embed/${this.youtubeId}`
  }

  get heading (): string {
    if (this.premiere) {
      const premiereDate = useHumanDate(useDate(this.published)).value
      return `Watch the new video “${this.title}” ${premiereDate}`
    }
    return this.rawHeading
  }

  isVisible (): boolean {
    return this.visible
  }

  hasYoutubeId (): boolean {
    return !!this.youtubeId
  }

  isPublished (): boolean {
    return !!this.published && (new Date(this.published) <= new Date())
  }

}

export default Video
