<script lang="ts" setup>
import Event, { type EventArtist, PerformanceType } from '@/models/event'
import useSlug from '@/composables/useSlug'

defineProps({
  event: {
    type: Event,
    required: true
  }
})

const commaList = (artists: EventArtist[], andMore: boolean = false): string => {
  const bandName = (artist: EventArtist): string => `<span class='text-stone-400'>${artist.name}</span>`
  const items = artists.map(artist => artist.name)
  if (andMore) {
    items.push('more')
  }
  if (items.length === 0) {
    return ''
  }
  if (items.length === 1) {
    return bandName(artists[0])
  }
  if (items.length === 2) {
    return `${bandName(artists[0])} and ${bandName(artists[1])}`
  }
  return items.map((item, index) => {
    const artist = { id: '', name: item, performanceType: PerformanceType.MAIN } as EventArtist
    if (index === items.length - 1) {
      return `and ${bandName(artist!)}`
    }
    return `${bandName(artist!)}`
  }).join(', ')
}

const buildArtistsNamesLine = (artists: EventArtist[], limit = 3): string => {
  const diseaseIllusion = artists.find(artist => artist.name === 'Disease Illusion')!
  const filteredArtists = artists.filter(artist => artist.name !== 'Disease Illusion')
  const mainArtists = filteredArtists.filter(artist => artist.performanceType === PerformanceType.MAIN)
  const supportArtists = filteredArtists.filter(artist => artist.performanceType === PerformanceType.SUPPORT)
  let availableSlots = limit
  let names: string = ''
  if (diseaseIllusion.performanceType === PerformanceType.MAIN) {
    if (mainArtists.length > 0) {
      names = `with ${commaList(mainArtists)}`
      availableSlots -= mainArtists.length
    }
    let limitedSupportArtists = supportArtists.slice(0, availableSlots)
    if (limitedSupportArtists.length > 0) {
      names += ` supported by ${commaList(limitedSupportArtists, supportArtists.length > availableSlots)}`
    }
  }

  if (diseaseIllusion.performanceType === PerformanceType.SUPPORT) {
    if (mainArtists.length > 0) {
      names = `supporting ${commaList(mainArtists)}`
      availableSlots -= mainArtists.length
    }
    let limitedSupportArtists = supportArtists.slice(0, availableSlots)
    if (limitedSupportArtists.length > 0) {
      names += ` with ${commaList(limitedSupportArtists, supportArtists.length > availableSlots)}`
    }
  }

  return names
}

const buildRoute = (event: Event): string => {
  const slug = useSlug(event.getStartDate() + ' ' + event.getCity() + ' ' + event.getCountry(), false)
  return `/shows/${event.id}/${slug}`
}

</script>

<template>
  <a
    :class="[
      event.isPast() ? 'bg-stone-900 hover:bg-stone-800' : 'bg-stone-800 hover:bg-stone-700',
      event.canceled ? 'bg-red-900 bg-opacity-10' : ''
      ]"
    :href="buildRoute(event)"
    class="w-full flex flex-row justify-center text-left bg-opacity-80 my-4 p-5 mx-auto rounded-lg text-white"
  >
    <div class="basis-3/4 items-left">
      <div class="pb-2">
        <div v-if="!event.isPast()" class="uppercase text-sm font-semibold">
          <span class="">{{ event.getStartDate() }}</span>
          <span class="p-2 text-gray-500">•</span>
          <span v-if="!event.canceled" class="text-xs text-gray-400">{{ event.getStartTime() }}</span>
          <span v-if="event.canceled" class="text-xs text-red-500">Canceled</span>
        </div>
        <div v-if="event.isPast()" class="uppercase text-sm">
          <span v-if="!event.canceled" class="text-xs text-gray-400">Past event</span>
          <span v-if="event.canceled" class="text-xs text-red-500">Canceled event</span>
          <span class="p-2 text-gray-500">•</span>
          <span>{{ event.getStartDate() }}</span>
        </div>
      </div>
      <div class="pb-2">
        <div class="text-xl font-semibold">{{ event.getCity() }}, {{ event.getCountry() }}</div>
        <div class="text-sm font-normal">{{ event.isFestival() ? event.getName() : event.getVenue() }}</div>
      </div>
      <div
        v-if="event.hasSupport()"
        class="text-xs text-gray-400"
        v-html="buildArtistsNamesLine(event.artists, 4)"
      ></div>
    </div>
    <div class="basis-1/4 content-center text-center">
      <a
        v-if="event.hasLinks() && ((!event.isPast() && !event.canceled) || event.isPast())"
        :href="event.getRelevantLink()"
        target="_blank"
      >
        <button
          class="uppercase text-sm bg-gray-100 text-black font-semibold py-2 px-20 rounded hover:bg-stone-900 hover:text-main focus:outline-none focus:ring-2 focus:bg-gray-300 focus:ring-opacity-50 transition duration-300">
          {{ event.isPast() ? 'View' : 'Tickets' }}
        </button>
      </a>
    </div>
  </a>
</template>

<style scoped>

</style>
