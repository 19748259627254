import { Model } from '@/models/model'
import type { RawRecording } from '@/data'
import type Artist from '@/models/artist'
import { useDB } from '@/composables/useDB'
import type Work from '@/models/work'
import { useHumanDuration } from '@/composables/useDate'

export class Recording extends Model {
  readonly id: string
  readonly name: string
  readonly type: string
  readonly context: string
  readonly contributor: {
    type: string;
    contributor: {
      id: string;
      name: string;
      type: string[];
    };
    roleName: string;
  }[]
  readonly duration: string
  readonly isrcCode: string
  readonly producer: {
    id: string;
    name: string;
    type: string[];
  }
  readonly recordingOf: {
    id: string;
    name: string;
    type: string;
  }

  constructor (recording: RawRecording) {
    super(recording)
    this.id = recording.id
    this.name = recording.name
    this.type = recording.type
    this.context = recording.context
    this.contributor = recording.contributor
    this.duration = recording.duration
    this.isrcCode = recording.isrcCode
    this.producer = recording.producer
    this.recordingOf = recording.recordingOf
  }

  private parseDuration (duration: string): number {
    const matches = duration.match(/PT(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?/)
    if (!matches) {
      return 0
    }
    const hours = parseInt(matches[1] || '0', 10)
    const minutes = parseInt(matches[2] || '0', 10)
    const seconds = parseInt(matches[3] || '0', 10)
    return (hours * 60 * 60) + (minutes * 60) + seconds
  }

  get work (): Work {
    return useDB().works.findById(this.recordingOf.id)
  }

  get artist (): Artist {
    return useDB().artist
  }

  get ISRC (): string {
    return this.isrcCode
  }

  get durationInSeconds (): number {
    return this.parseDuration(this.duration)
  }

  get humanReadableDuration (): string {
    return useHumanDuration(this.duration).value
  }
}

export default Recording
