<script lang="ts" setup>
import NavbarComponent from '@/components/NavbarComponent.vue'
import FootComponent from '@/components/FootComponent.vue'
import EventComponent from '@/components/EventComponent.vue'
import SectionComponent from '@/components/SectionComponent.vue'
import Event from '@/models/event'
import { useEvents } from '@/composables/useDB'

const sortByDateAscending = (a: Event, b: Event): number => a.startDate > b.startDate ? -1 : 1
const sortByDateDescending = (a: Event, b: Event): number => a.startDate > b.startDate ? 1 : -1

const events = useEvents().sort(sortByDateAscending)
const allEvents = {
  future: events.filter(event => !event.isPast()).sort(sortByDateDescending),
  past: events.filter(event => event.isPast()).sort(sortByDateAscending)
}
</script>

<template>
  <NavbarComponent/>
  <SectionComponent
    id="shows"
    :parallax="true"
    background="rotellone_black_background"
    background-class="bg-cover bg-fixed bg-center bg-no-repeat"
    title="Shows"
  >
    <div v-for="(events, type, index) in allEvents" :key="index">
      <h3 v-if="type === 'future' && allEvents.future.size() > 0" class="text-sm">Upcoming</h3>
      <h3 v-if="type === 'past' && (allEvents.future.size() > 0 && allEvents.past.size() > 0)" class="text-sm">Past
        Shows</h3>
      <EventComponent v-for="event in events.items" :key="event.id" :event="event"/>
    </div>
  </SectionComponent>
  <FootComponent/>
</template>
