import { Model } from '@/models/model'
import type { RawWork } from '@/data'

export class Work extends Model {
  readonly id: string
  readonly name: string
  readonly type: string
  readonly composer: {
    id: string;
    name: string;
    type: string[];
  }[]
  readonly context: string
  readonly inLanguage: string
  readonly iswcCode: string
  readonly lyricist: {
    id: string;
    name: string;
    type: string[];
  }

  constructor (work: RawWork) {
    super(work)
    this.id = work.id
    this.name = work.name
    this.type = work.type
    this.composer = work.composer
    this.context = work.context
    this.inLanguage = work.inLanguage
    this.iswcCode = work.iswcCode
    this.lyricist = work.lyricist
  }
}

export default Work
