<script lang="ts" setup>
import SectionComponent from '@/components/SectionComponent.vue'
import { useArtist } from '@/composables/useDB'
import Assets from '@/plugins/assets'
import { useMediaQuery } from '@vueuse/core'
import ImageResponsive from '@/components/ImageResponsive.vue'

const members = useArtist().currentMembers()

const styleBackgroundImage = (assetName: string): string => {
  const asset = Assets.get(assetName)
  switch (true) {
    case useMediaQuery('(max-width: 300px)').value:
      return asset.getResized(300).getPath()
    case useMediaQuery('(min-width: 301px) and (max-width: 575px)').value:
      return asset.getResized(300).getPath()
    case useMediaQuery('(min-width: 576px) and (max-width: 767px)').value:
      return asset.getResized(576).getPath()
    case useMediaQuery('(min-width: 768px) and (max-width: 991px)').value:
      return asset.getResized(768).getPath()
    case useMediaQuery('(min-width: 992px) and (max-width: 1199px)').value:
      return asset.getResized(992).getPath()
    case useMediaQuery('(min-width: 1200px) and (max-width: 1535px)').value:
      return asset.getResized(1200).getPath()
    case useMediaQuery('(min-width: 1536px) and (max-width: 1919px)').value:
      return asset.getResized(1536).getPath()
    case useMediaQuery('(min-width: 1920px) and (max-width: 2559px)').value:
      return asset.getResized(1920).getPath()
    case useMediaQuery('(min-width: 2560px) and (max-width: 3839px)').value:
      return asset.getResized(2560).getPath()
    case useMediaQuery('(min-width: 3840px) and (max-width: 4095px)').value:
      return asset.getResized(3840).getPath()
    case useMediaQuery('(min-width: 4096px)').value:
      return asset.getResized(4096).getPath()
    default:
      return asset.getResized(1920).getPath()
  }
}
</script>

<template>
  <SectionComponent id="band" :parallax="false" class="!p-0 !sm:p-0 bg-cover bg-center bg-no-repeat">
    <ImageResponsive :local="'diseaseillusion_2024_16_9'" alt="Disease Illusion"/>
    <!--<div-->
    <!--  v-for="member in members.items"-->
    <!--  :key="member.id"-->
    <!--  :class="[-->
    <!--    'bg-top',-->
    <!--    'bg-no-repeat',-->
    <!--    'bg-[length:200%]',-->
    <!--    'bg-scroll',-->
    <!--    `w-1/${members.size()}`,-->
    <!--    'h-96',-->
    <!--    'border-1',-->
    <!--    'inline-block'-->
    <!--    ]"-->
    <!--  :style="{ backgroundImage: `url('${styleBackgroundImage(member.assetName)}')` }"-->
    <!--&gt;&nbsp;-->
    <!--</div>-->
  </SectionComponent>
</template>

<style>

</style>
