import { Model } from '@/models/model'
import type { RawEvent } from '@/data'
import { useDate } from '@/composables/useDate'
import dayjs from 'dayjs'

export enum PerformanceType {
  MAIN = 'main',
  SUPPORT = 'support',
  GUEST = 'guest'
}

export type EventLocationArea = {
  id: string;
  name: string;
  type: string;
  containedIn?: EventLocationArea;
}

export type EventLocation = {
  id: string;
  name: string;
  address: string;
  area: EventLocationArea;
  coordinates: {
    latitude: number;
    longitude: number;
  } | undefined;
}

export type EventArtist = {
  id: string;
  name: string;
  performanceType: PerformanceType;
}

/**
 * Events are from the point of view of the band
 * The "performanceType" refers to the role the band has/had in the event
 */
class Event extends Model {
  readonly id: string
  readonly name: string
  readonly type: string
  readonly canceled: boolean
  readonly startDate: dayjs.Dayjs
  readonly endDate: dayjs.Dayjs | undefined
  readonly location?: EventLocation
  readonly artists: EventArtist[]
  readonly links: {
    url: string;
    type: string;
  }[]
  readonly images?: {
    image: string;
    250: string;
    500: string;
    1200: string;
    large: string;
    small: string;
  }

  constructor (event: RawEvent) {
    super(event)
    this.id = event.id
    this.name = event.name
    this.type = event.type
    this.canceled = event.cancelled
    this.startDate = useDate(event['life-span'].begin + ' ' + event.time)
    this.endDate = event['life-span'].end ? useDate(event['life-span'].end + ' ' + event.time) : undefined
    const place = event.relations.find(relation => relation.type === 'held at')
    if (place?.place) {
      this.location = {
        id: place.place.id,
        name: place.place.name,
        address: place.place.address,
        area: place.place.area,
        coordinates: place.place.coordinates ? {
          latitude: place.place.coordinates.latitude,
          longitude: place.place.coordinates.longitude
        } : undefined
      }
    }
    this.artists = event.relations
      .filter(relation => relation['target-type'] === 'artist')
      .map(relation => ({
        id: relation.artist!.id,
        name: relation.artist!.name,
        performanceType: this.parsePerformanceType(relation.type)
      }))
    this.links = event.relations.filter(relation => relation['target-type'] === 'url').map(relation => {
      return {
        url: relation.url!.resource,
        type: relation.type
      }
    })
    this.images = event.images[0] ? {
      image: event.images[0].image,
      250: event.images[0].thumbnails['250'],
      500: event.images[0].thumbnails['500'],
      1200: event.images[0].thumbnails['1200'],
      large: event.images[0].thumbnails.large,
      small: event.images[0].thumbnails.small
    } : undefined
  }

  getName (): string {
    return this.name
  }

  isPast (): boolean {
    return this.endDate ? this.endDate.isBefore(dayjs()) : this.startDate.isBefore(dayjs())
  }

  getStartDate (): string {
    return this.startDate.format('dddd D MMMM' + (this.startDate.year() !== dayjs().year() ? ' YYYY' : ''))
  }

  getStartTime (): string {
    return this.startDate.format('HH:mm')
  }

  isFestival (): boolean {
    return this.type === 'Festival'
  }

  getVenue (): string | undefined {
    return this.location?.name
  }

  getAddress (): string | undefined {
    return this.location?.address
  }

  getMapLink (): string | undefined {
    const base = 'https://www.google.com/maps/search/?api=1&query='
    const coordinates = this.location?.coordinates ? `${this.location?.coordinates?.latitude},${this.location?.coordinates?.longitude}` : undefined
    const useAddress = this.getAddress()
    const useCity = this.getCity()
    const useCountry = this.getCountry()
    return coordinates || useAddress || useCity || useCountry ? `${base}${coordinates || useAddress || useCity || useCountry}` : undefined
  }

  getCity (): string | undefined {
    return this.getAdministrativeAreaName('City')
  }

  getCountry (): string | undefined {
    return this.getAdministrativeAreaName('Country')
  }

  hasSupport (): boolean {
    return this.artists.length > 0
  }

  hasLinks (): boolean {
    return this.links.length > 0
  }

  getRelevantLink (): string | undefined {
    const types = ['ticketing', 'songkick', 'social network', 'official homepage', 'last.fm']
    for (const type of types) {
      const link = this.links.find(link => link.type.includes(type))
      if (link) {
        return link.url
      }
    }
  }

  getImage (size: string): string | undefined {
    return this.images?.[size as keyof typeof this.images]
  }

  private getAdministrativeAreaName (type: string): string | undefined {
    let area = this.location?.area
    while (area?.type !== type) {
      if (!area?.containedIn) {
        return this.location?.area.name
      }
      area = area?.containedIn
    }
    return area?.name
  }

  private parsePerformanceType (type: string): PerformanceType {
    if (type.includes('main')) {
      return PerformanceType.MAIN
    }
    if (type.includes('support')) {
      return PerformanceType.SUPPORT
    }
    if (type.includes('guest')) {
      return PerformanceType.GUEST
    }
    return PerformanceType.SUPPORT
  }
}

export default Event
