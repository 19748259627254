<script lang="ts" setup>

import { computed } from 'vue'
import { useMediaQuery } from '@vueuse/core'
import Assets from '@/plugins/assets'

const props = defineProps<{
  id: string;
  title?: string;
  background?: string;
  backgroundClass?: string;
  opacity?: number;
  parallax?: boolean;
  class?: string;
}>()

const styleBackgroundImage = computed(() => {
  if (!props.background) return ''
  const asset = Assets.get(props.background)
  switch (true) {
    case useMediaQuery('(max-width: 300px)').value:
      return asset.getResized(300).getPath()
    case useMediaQuery('(min-width: 301px) and (max-width: 575px)').value:
      return asset.getResized(300).getPath()
    case useMediaQuery('(min-width: 576px) and (max-width: 767px)').value:
      return asset.getResized(576).getPath()
    case useMediaQuery('(min-width: 768px) and (max-width: 991px)').value:
      return asset.getResized(768).getPath()
    case useMediaQuery('(min-width: 992px) and (max-width: 1199px)').value:
      return asset.getResized(992).getPath()
    case useMediaQuery('(min-width: 1200px) and (max-width: 1535px)').value:
      return asset.getResized(1200).getPath()
    case useMediaQuery('(min-width: 1536px) and (max-width: 1919px)').value:
      return asset.getResized(1536).getPath()
    case useMediaQuery('(min-width: 1920px) and (max-width: 2559px)').value:
      return asset.getResized(1920).getPath()
    case useMediaQuery('(min-width: 2560px) and (max-width: 3839px)').value:
      return asset.getResized(2560).getPath()
    case useMediaQuery('(min-width: 3840px) and (max-width: 4095px)').value:
      return asset.getResized(3840).getPath()
    case useMediaQuery('(min-width: 4096px)').value:
      return asset.getResized(4096).getPath()
    default:
      return asset.getResized(1920).getPath()
  }
})

const isMobile = useMediaQuery('(max-width: 575px)').value

</script>

<template>
  <section
    :id="props.id"
    :class="[
      props.class,
      ['relative', 'w-full', 'h-full', 'px-5', 'sm:px-20', 'py-5', 'text-white', 'bg-black', 'm-auto'],
    ]"
  >
    <div
      :class="[
        props.backgroundClass ?? 'bg-center bg-no-repeat bg-contain bg-scroll',
        ['absolute', 'inset-0', 'z-0'],
        {
          'overflow-y-scroll bg-fixed': props.parallax,
          'bg-scroll': isMobile || !props.parallax,
          'bg-fixed': !isMobile && props.parallax,
        },
      ]"
      :style="[
        background && { backgroundImage: `url('${styleBackgroundImage}')` },
        { opacity: props.opacity ?? 1 },
      ]"
    />
    <div class="relative z-10">
      <h2 v-if="props.title" class="text-2xl text-center">{{ props.title }}</h2>
      <slot/>
    </div>
  </section>
</template>

<style scoped>
</style>
