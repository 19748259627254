import { Model } from '@/models/model'
import type { RawProduct } from '@/data'

class Product extends Model {
  url: string
  sku: string
  name: string
  price: number
  currency: string
  image: string

  constructor (product: RawProduct) {
    super(product)
    this.url = product.url
    this.sku = product.sku
    this.name = product.name
    this.price = product.price
    this.currency = product.currency
    this.image = product.image
  }
}

export default Product
