import useSlug from '@/composables/useSlug'

export interface ModelInterface {
  readonly id?: string;
  readonly name?: string;
}

export class Model implements ModelInterface {
  readonly id?: string;
  readonly name?: string;

  constructor (model: Partial<ModelInterface>) {
    this.id = model.id
    this.name = model.name
  }

  get shortSlug (): string {
    return useSlug(this.name!, true)
  }

  get longSlug (): string {
    return useSlug(this.name!, false)
  }
}

export class ModelWithAsset extends Model {
  get assetName (): string {
    return this.longSlug
  }
}

export interface CollectionInterface {
  items: Set<ModelInterface>;
}

export class Collection<T extends Model> implements CollectionInterface {
  class: string;
  items: Set<T>

  constructor (items: T[], classType?: string) {
    this.items = new Set<T>(items)
    this.class = items[0]?.constructor.name || classType || 'Generic Collection'
  }

  size (): number {
    return this.items.size
  }

  map <I>(fn: (item: T) => I): I[] {
    return [...this.items].map(fn)
  }

  sort (fn: (a: T, b: T) => number): Collection<T> {
    return new Collection<T>([...this.items].sort(fn))
  }

  findBy (key: keyof T, value: string): T {
    let foundItem: T | undefined
    this.items.forEach((item: T) => {
      if (!item[key]) {
        throw new Error(`${this.class} does not have a property "${String(key)}"`)
      }
      if (item[key] === value) {
        foundItem = item
      }
    })
    if (!foundItem) {
      throw new Error(`No ${this.class} found with ${String(key)} "${value}"`)
    }
    return foundItem
  }

  findById (id: string): T {
    return this.findBy('id', id)
  }

  findByName (name: string): T {
    return this.findBy('name', name)
  }

  filter (fn: (item: T, index: number, array: T[]) => boolean): Collection<T> {
    return new Collection<T>([...this.items].filter(fn), this.class)
  }

  take (n?: number): Collection<T> {
    return new Collection<T>([...this.items].slice(0, n), this.class)
  }

}
