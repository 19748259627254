import { decode } from 'blurhash'

const useBlurhash = async (blurhash: string, width: number, height: number): Promise<string> => {
  const pixels = decode(blurhash, width, height)
  const canvas = document.createElement('canvas')
  canvas.width = width
  canvas.height = height
  const context = canvas.getContext('2d')
  if (!context) throw new Error('Could not get 2d context')
  const imageData = context.createImageData(width, height)
  imageData.data.set(pixels)
  context.putImageData(imageData, 0, 0)
  return canvas.toDataURL()
}

export default useBlurhash
