import { Collection, Model } from '@/models/model'
import type { RawAlbum } from '@/data'
import type Artist from '@/models/artist'
import { useDB } from '@/composables/useDB'
import type Release from '@/models/release'

export enum AlbumProductionType {
  StudioAlbum = 'studio',
  LiveAlbum = 'live',
  DemoAlbum = 'demo',
  CompilationAlbum = 'compilation',
}

export enum AlbumReleaseType {
  AlbumRelease = 'album',
  SingleRelease = 'single',
  EPRelease = 'ep',
}

class Album extends Model {
  readonly id: string
  readonly name: string
  readonly type: string
  /** ie: "albumProductionType": "https://schema.org/StudioAlbum" */
  readonly albumProductionType: string
  /** ie: "albumReleaseType": "https://schema.org/SingleRelease" */
  readonly albumReleaseType: string
  readonly byArtist: {
    id: string;
    name: string;
    type: string;
    genre: string[];
  }
  readonly creditedTo: string

  constructor (album: RawAlbum) {
    super(album)
    this.id = album.id
    this.name = album.name
    this.type = album.type
    this.albumProductionType = album.albumProductionType
    this.albumReleaseType = album.albumReleaseType
    this.byArtist = album.byArtist
    this.creditedTo = album.creditedTo
  }

  get productionType (): AlbumProductionType {
    const type = this.albumProductionType.split('/').pop()
    return AlbumProductionType[type as keyof typeof AlbumProductionType]
  }

  get releaseType (): AlbumReleaseType {
    const type = this.albumReleaseType.split('/').pop()
    return AlbumReleaseType[type as keyof typeof AlbumReleaseType]
  }

  get artist (): Artist {
    return useDB().artist
  }

  get releases (): Collection<Release> {
    return useDB().releases.filter(release => release.releaseOf.id === this.id)
  }
}

export default Album
